import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import wwfIcon from "../assets/WWF_logo 1.svg";
import redCrossIcon from "../assets/redcross.svg";
import redCrossIcon2 from "../assets/red-cross-logo.svg";
import sosIcon from "../assets/sos.svg";
import dfgLogo from "../assets/Group 80.svg";
import remove from "../assets/remove.svg";

const PreDonateComponent = (props) => {
  const {
    preDefindAmounts,
    setOpenPaymentMethods,
    onOrganizationChange,
    onAmountChange,
  } = props;
  const [organization, setOrganization] = useState(null);
  const [name, setName] = useState(null);
  const [amount, setAmount] = useState(null);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [isDisabled, setIsDisabled] = useState(true);
  const [showValuePopup, setShowValuePopup] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const divRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (divRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = divRef.current;
        // Enable button when scrolled to the bottom or a specific position
        if (scrollTop + clientHeight >= scrollHeight - 100) {
          setIsDisabled(false);
        }
      }
    };
  }, []);

  const arrayTypes = [
    {
      value: "world_wildlife_fund",
      label: t("value1"),
      icon: wwfIcon,
      icon2: wwfIcon,
      description: t("text1"),
      title: t("title"),
    },
    {
      value: "red_cross",
      label: t("value2"),
      icon: redCrossIcon,
      icon2: redCrossIcon2,
      description: t("text2"),
      title: t("title1"),
    },
    {
      value: "sos_children_villages",
      label: t("value3"),
      icon: sosIcon,
      icon2: sosIcon,
      description: t("text3"),
      title: t("title2"),
    },
  ];

  const [checkBoxState, setCheckBoxState] = useState(
    Array(arrayTypes.length).fill(false)
  );

  const [showPopup, setShowPopup] = useState(false);
  const [selectedOrgContent, setSelectedOrgContent] = useState("");
  const [selectedOrgTitle, setSelectedOrgTitle] = useState("");
  const [icon, setIcon] = useState("");

  const handleReadMoreClick = (content, title, icon) => {
    setSelectedOrgContent(content);
    setSelectedOrgTitle(title);
    setShowPopup(true);
    setIcon(icon);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  // useEffect(() => {
  //   const query = new URLSearchParams(location.search);
  //   const queryOrganization = query.get("organization");
  //   const personName = query.get("firstName") || "Person";
  //   if (personName) {
  //     setName(personName);
  //   }
  //   if (queryOrganization) {
  //     setOrganization(queryOrganization);
  //   }
  // }, [location.search]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const queryOrganization = query.get("organization");
    const personName = query.get("firstName") || "Person";
    if (personName) {
      setName(personName);
    }
    if (queryOrganization) {
      setOrganization(queryOrganization);
    }

    // Check if the popup has been shown before
  }, [location.search]);

  // useEffect(() => {
  //   const isPopupShown = localStorage.getItem("popupShown");
  //   if (!isPopupShown) {
  //     setShowValuePopup(true);
  //   }
  // }, []);

  const handleOrganizationChange = (selectedOption) => {
    setOrganization(selectedOption.value);
    onOrganizationChange(selectedOption.value);
  };

  const handleAmountChange = (selectedOption) => {
    setAmount(selectedOption.value);
    onAmountChange(selectedOption.value);
  };

  const isSelectPaymentDisabled = () => {
    return !organization || !amount || !isChecked;
  };

  const handleProceedToPayment = () => {
    setOpenPaymentMethods(true);
  };

  const handleCheckboxClick = (index) => {
    const newCheckBoxState = [...checkBoxState];
    newCheckBoxState[index] = !newCheckBoxState[index];
    setCheckBoxState(newCheckBoxState);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 10,
        marginRight: 10,
      }}
    >
      <span className="title">{t("youAreDonating")}</span>

      {/* Read More Popup */}
      {showPopup && (
        <div className="blur-background" onClick={closePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <div className="title-x">
              <div className="title-popup">{selectedOrgTitle}</div>
              <div className="close-btn" onClick={closePopup}>
                <img src={remove} alt="close" />
              </div>
            </div>
            <div className="logos">
              <img
                src={dfgLogo}
                alt="dfgLogo"
                style={{
                  marginRight: 8,
                  height: 80,
                  width: 80,
                  marginBottom: 10,
                }}
              />
              <img
                src={icon}
                alt="icon"
                style={{
                  marginRight: 8,
                  marginBottom: 10,
                }}
              />
            </div>
            <p className="text-organ">{selectedOrgContent}</p>
          </div>
        </div>
      )}

      {showValuePopup && (
        <div
          className="blur-background"
          onClick={() => setShowValuePopup(false)}
        >
          <div
            className="popup-content"
            onClick={(e) => e.stopPropagation()}
            style={{
              padding: "30px",
              overflowY: "scroll",
            }}
            ref={divRef} // Reference to the scrollable div
          >
            <div className="titleX">
              <div className="title-popup">{selectedOrgTitle}</div>
              <div
                className="close-btn"
                onClick={() => setShowValuePopup(false)}
              >
                <img src={remove} alt="close" />
              </div>
            </div>
            {/* Added Texts */}
            <div className="title-popup">{t("title3")}</div>
            <div className="title-popup">{t("heading")}</div>

            <h3>{t("valueTitle1")}</h3>
            <p className="valueText">{t("description1")}</p>
            <ol>
              <li className="valueText">{t("step1")}</li>
              <li className="valueText">{t("step2")}</li>
              <li className="valueText">{t("step3")}</li>
              <li className="valueText">{t("step4")}</li>
            </ol>

            <h3>{t("title4")}</h3>
            <p className="valueText">{t("description2")}</p>

            <h3>{t("title5")}</h3>
            <p className="valueText">{t("description3")}</p>

            <h3>{t("title6")}</h3>
            <p className="valueText">{t("description4")}</p>

            <h3>{t("title7")}</h3>
            <p className="valueText">{t("description5")}</p>

            <h3>{t("title8")}</h3>
            <p className="valueText">{t("description6")}</p>

            <h3>{t("title9")}</h3>
            <p className="valueText">{t("description7")}</p>

            <h3>{t("title10")}</h3>
            <p className="valueText">{t("description8")}</p>

            <h3>{t("title11")}</h3>
            <p className="valueText">{t("description9")}</p>

            <h3>{t("title12")}</h3>
            <p className="valueText">{t("description10")}</p>

            <h3>{t("title13")}</h3>
            <p className="valueText">{t("description11")}</p>

            <h3>{t("title14")}</h3>
            <p className="valueText">{t("description12")}</p>

            <h3>{t("title15")}</h3>
            <p className="valueText">{t("description13")}</p>

            <h3>{t("title16")}</h3>
            <p className="valueText">{t("preTitle16")}</p>
            <ul>
              <li className="valueText">{t("description14.restriction1")}</li>
              <li className="valueText">{t("description14.restriction2")}</li>
              <li className="valueText">{t("description14.restriction3")}</li>
              <li className="valueText">{t("description14.restriction4")}</li>
            </ul>

            <h3>{t("title17")}</h3>
            <p className="valueText">{t("description15")}</p>

            <h3>{t("title18")}</h3>
            <p className="valueText">{t("description16")}</p>

            <h3>{t("title19")}</h3>
            <p className="valueText">{t("description17")}</p>

            <h3>{t("title20")}</h3>
            <p className="valueText">{t("description18")}</p>

            <h3>{t("title21")}</h3>
            <p className="valueText">{t("description19")}</p>

            <h3>{t("title22")}</h3>
            <p className="valueText">{t("description20")}</p>

            <h3>{t("title23")}</h3>
            <p className="valueText">{t("description21")}</p>
            <ol>
              <li className="valueText">{t("steps.step1")}</li>
              <li className="valueText">{t("steps.step2")}</li>
              <li className="valueText">{t("steps.step3")}</li>
              <li className="valueText">{t("steps.step4")}</li>
              <li className="valueText">{t("steps.step5")}</li>
              <li className="valueText">{t("steps.step6")}</li>
            </ol>
            <p className="valueText">{t("finalNote")}</p>

            <button
              style={{
                bottom: 20,
                color: "white",
                padding: "10px",
                border: "none",
                width: "100%",
                backgroundColor: "rgba(170, 0, 40, 1)",
              }}
              onClick={() => [setShowValuePopup(false), setIsChecked(true)]} // Corrected onClick
            >
              {t("accept")}
            </button>
          </div>
        </div>
      )}

      {/* Value Popup */}

      {/* Organizations Wrapper */}

      <div className="grid-container">
        {arrayTypes.map((type, index) => (
          <div
            className={
              type.value === organization
                ? "cardElementSelected"
                : "cardElement"
            }
            key={type.value}
            onClick={() => handleOrganizationChange(type)}
          >
            <div>
              <div className="checkboxWrapper">
                <div
                  className={
                    type.value === organization
                      ? "checkbox checked"
                      : "checkbox"
                  }
                  onClick={(event) => {
                    event.stopPropagation();
                    handleCheckboxClick(index);
                    handleOrganizationChange(type);
                  }}
                />
              </div>
              <div className="cardElementContainer" key={type.value}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={type.icon}
                    alt={type.label}
                    style={{}}
                    className="icon"
                  />
                  <span className="cardElementTitle">{type.label}</span>
                </div>
                <span
                  onClick={() =>
                    handleReadMoreClick(
                      type.description,
                      type.title,
                      type.icon2 || type.icon
                    )
                  }
                  className="read-more"
                >
                  {t("readMore")}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Amounts Section */}
      {preDefindAmounts.length > 0 && (
        <>
          <div className="title2">{t("amountOf")}</div>
          <div className="amountBox">
            {preDefindAmounts.map((type) => (
              <div
                className={
                  type.value === amount ? "amount-box" : "amount-box-selected"
                }
                key={type.value}
                onClick={() => handleAmountChange(type)}
              >
                <div key={type.value}>
                  <span>{type.label}</span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      <div className="terms-checkbox">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={(e) => setIsChecked(e.target.checked)}
        />
        <label style={{}}>
          {t("termsText1")}{" "}
          <span
            onClick={() => setShowValuePopup(true)}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            {t("termsText2")}
          </span>{" "}
        </label>
      </div>

      {/* Proceed Button */}
      <div className="btnDiv">
        {/* <span className="text">{t("text")}</span> */}
        <button
          disabled={isSelectPaymentDisabled()}
          id="donate1"
          onClick={handleProceedToPayment}
        >
          {isLoading ? (
            <span className="loader"></span>
          ) : (
            <span className="donate1"> {t("button1")}</span>
          )}
        </button>
      </div>
    </div>
  );
};

export default PreDonateComponent;
